import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import Helmet from '../components/Helmet'
import { MouseDispatch } from '../components/MouseContext'
import { HTMLContent } from '../components/Content'

const PageWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  padding: `2rem 0 4rem`,
  marginTop: 0,
  justifyContent: 'center',
  alignContent: 'center',
  alignItems: 'flex-start',
  minHeight: '100vh',
  width: '100vw'
})

const AboutPage = ({ data }) => {
  const mouseDispatch = useContext(MouseDispatch)
  const { markdownRemark: page } = data

  // stopPropagation for usage of HTMLContent inside main waypoint
  const handleClick = (event) => {
    event.stopPropagation()
  }
  const handleMouseEnter = (event) => {
    event.stopPropagation()
    mouseDispatch({ type: "enter", item: "content" })
  }
  const handleMouseLeave = (event) => {
    event.stopPropagation()
    mouseDispatch({ type: "enter", item: "main" })
  }
  return (
    <React.Fragment>
      <Helmet />
      <PageWrapper>
        <HTMLContent
          content={page.html}
          onClick={handleClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
      </PageWrapper>
    </React.Fragment>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage

export const pageQuery = graphql`
  query AboutPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "about-page" } }) {
      frontmatter {
        title
      }
      html
    }
  }
`
